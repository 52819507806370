<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-view-compact</v-icon>
          <h1 class="darkGrey--text">Item Types</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Agenda Styles
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
          v-if="boardSelected!==''"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Item Type
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex mb-6">
      <v-col
        class="d-flex"
        cols="12"
        sm="6"
      >
        <v-select
          :key="Math.random()"
          :items="activeBoards"
          label="Board"
          :clearable="true"
          v-model="boardSelected"
          :item-value="item => item.id"
          :item-text="item => item.name"
          hide-details
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemTypes"
            :server-items-length="totalItemsCount"
            :options.sync="options"
            :loading="loading"
            :search="search"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.name="{ item }">
              <span class="py-6 d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.type }}
                </span>
                <span class="text-caption">
                  {{ item.description }}
                </span>
              </span>
            </template>
            <template v-slot:item.sections="{ item }">
              <v-tooltip top v-if="item.data_sections.length>0">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.data_sections.length }}
                  </v-chip>
                </template>
                <span class="custom-tooltip-list">
                  <v-row>
                    <v-col>
                      <span class="custom-tooltip-title">
                        DATA SECTIONS
                      </span>
                      <ul>
                        <li v-for="item in item.data_sections" :key="item.id + Math.random()">
                          {{ item.name }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.coversheets="{ item }">
              <v-tooltip top v-if="item.cover_sheets.length>0">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.cover_sheets.length }}
                  </v-chip>
                </template>
                <span class="custom-tooltip-list">
                  <v-row>
                    <v-col>
                      <span class="custom-tooltip-title">
                        COVER SHEETS
                      </span>
                      <ul>
                        <li v-for="item in item.cover_sheets" :key="item.id + Math.random()">
                          {{ item.name }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-tooltip top v-if="item.pending">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-timer-sand
                  </v-icon>
                </template>
                <span>
                  Pending
                </span>
              </v-tooltip>
              <v-tooltip top v-if="item.active">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="positive"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>
                  Active
                </span>
              </v-tooltip>
              <v-tooltip top v-if="item.paused">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="important"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pause
                  </v-icon>
                </template>
                <span>
                  Paused
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item } ">
              <div class="d-flex flex-row justify-center">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }" v-slot:item.actions="{ item }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'ItemTypeDataSections', params: {typeid: item.id, itemTypeProp: item } })"
                    >
                      <v-icon size="20">
                        mdi-form-textbox
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Data Sections
                  </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }" v-slot:item.actions="{ item }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'ItemTypeCoverSheets', params: {typeid: item.id, itemTypeProp: item } })"
                    >
                      <v-icon size="20">
                        mdi-file
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Cover Sheets
                  </span>
                </v-tooltip>

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <item-type-create-update @reload="getDataFromApi" :itemType="itemType" v-model="modals.createUpdate" :modeType="modeType" :boardSelected="boardSelected" :boardName="boardName" @parent-function="parentFunction"></item-type-create-update>
    <item-type-associate-agenda @reload="getDataFromApi" :itemType="itemType" v-model="modals.associate"></item-type-associate-agenda>
    <item-type-resume @reload="getDataFromApi" :itemType="itemType" v-model="modals.resume"></item-type-resume>
    <item-type-pause @reload="getDataFromApi" :itemType="itemType" v-model="modals.pause"></item-type-pause>
    <item-type-activate @reload="getDataFromApi" :itemType="itemType" v-model="modals.activate"></item-type-activate>
    <item-type-delete @reload="getDataFromApi" :itemType="itemType" v-model="modals.delete"></item-type-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import ItemTypeCreateUpdate from '@/components/ItemTypes/ItemTypeCreateUpdate'
import ItemTypeAssociateAgenda from '@/components/ItemTypes/ItemTypeAssociateAgenda'
import ItemTypeResume from '@/components/ItemTypes/ItemTypeResume'
import ItemTypePause from '@/components/ItemTypes/ItemTypePause'
import ItemTypeActivate from '@/components/ItemTypes/ItemTypeActivate'
import ItemTypeDelete from '@/components/ItemTypes/ItemTypeDelete'
import ItemType from '@/models/ItemType'
import axios from 'axios'

export default Vue.extend({
  name: 'ItemTypes',
  components: {
    Portal,
    ItemTypeCreateUpdate,
    ItemTypeAssociateAgenda,
    ItemTypeResume,
    ItemTypePause,
    ItemTypeActivate,
    ItemTypeDelete
  },
  data: () => ({
    options: {},
    search: '',
    loading: false,
    itemTypes: [],
    itemType: {},
    totalItemsCount: 0,
    boardName: '',
    activeBoards: [],
    modeType: '',
    modals: {
      createUpdate: false,
      associate: false,
      resume: false,
      pause: false,
      activate: false,
      delete: false
    },
    breadcrumbsItems: [
      {
        text: 'Templates',
        disabled: false,
        href: ''
      },
      {
        text: 'Item Types',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-format-list-numbered', text: 'Associate with Agenda Style', actions: 'associate' },
      { icon: 'mdi-content-copy', text: 'Duplicate', actions: 'createUpdate', optional: 'duplicate' },
      { icon: 'mdi-check-circle-outline', text: 'Activate', actions: 'activate' },
      { icon: 'mdi-play', text: 'Resume', actions: 'resume' },
      { icon: 'mdi-pause', text: 'Pause', type: 'important', actions: 'pause' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Data Sections', value: 'sections', sortable: false, align: 'center' },
      { text: 'Cover Sheets', value: 'coversheets', sortable: false, align: 'center' },
      { text: 'Status', value: 'status', sortable: false, align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    boardSelected: ''
  }),
  methods: {
    openModal (item, optional, itemType) {
      if (optional) this.modeType = optional
      this.modals[item] = true
      if (itemType) this.itemType = itemType
    },
    parentFunction () {
      this.openModal('datacover')
    },
    async getDataFromApi () {
      this.loading = true
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage
      } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }
      const activeBoardIds = this.activeBoards.map(item => item.id)
      let itemTypeQuery = ItemType.where('client_id', this.$store.state.client.activeClient.clientID)
      if (this.boardSelected) {
        itemTypeQuery = itemTypeQuery.where('board_id', this.boardSelected)
      } else {
        itemTypeQuery = itemTypeQuery.whereIn('board_id', activeBoardIds)
      }
      const totalCountResponse = await itemTypeQuery.get()
      this.totalItemsCount = totalCountResponse.data.length

      const itemTypeResults = await itemTypeQuery
        .page(page ?? 1)
        .limit(itemsPerPage ?? 10)
        .orderBy(orderBy)
        .get()
      this.itemTypes = itemTypeResults.data
      this.itemTypes.forEach(elm => {
        elm.active = false
        elm.pending = false
        elm.paused = false
        elm.reserved = false
        elm[elm.status] = true

        if (elm.status === 'inactive') {
          elm.paused = true
        }

        elm.coversheets = 1
        elm.sections = 1
      })
      this.loading = false
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []

      if (item.status === 'pending') {
        itemsToRemove.push('resume', 'pause')
      }

      if (item.status === 'active') {
        itemsToRemove.push('resume', 'activate')
      }

      if (item.status === 'inactive') {
        itemsToRemove.push('pause', 'activate')
      }

      return this.itemsMenu.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    },
    getActiveBoards () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/boards-by-client-id/' + this.$store.state.client.activeClient.clientID)
        .then((res) => {
          this.activeBoards = res.data
        })
    }
  },
  mounted () {
    this.getActiveBoards()
    this.getDataFromApi()
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    boardSelected () {
      this.boardName = this.activeBoards.find(item => item.id === this.boardSelected)?.name ?? ''
      this.getDataFromApi()
    }
  }
})
</script>
